.timeline-element{
  border-radius: 50px;
  height: 20px;
  position: absolute;  // position is defined according to Timeline data (added at runtime)
  overflow: visible;

  .info{
    border-left: 1px solid var(--gray1);
    z-index: -1;
    position: absolute;
    left: 7px;
    color: var(--gray1);
    height: 130px;
    width: 250px;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    transition: all .4s ease-in-out;
    transform-origin: top;
    overflow: visible;

    .text{
      padding-left: 5px;

      h3{
        font-size: 17px;
      }

      h5{
        font-size: 13px;
      }

      p.title{
        font-size: 12px;
        font-weight: 400;
      }

      .description{
        ul {
          padding-top: 10px;
          padding-left: 0px;
          list-style-type: none;
          font-size: 11px;
          font-weight: 400;

          li{
            padding-top: 5px;
          }
        }
      }
    }

    .description-enter {
      opacity: 0;
    }

    .description-enter-active {
      opacity: 1;
      transition: opacity 1000ms;
    }

    .description-enter-done {
      opacity: 1;
    }

    .description-exit {
      opacity: 1;
    }

    .description-exit-active {
      opacity: 0;
      transition: opacity 300ms;
    }

    .description-exit-done {
      opacity: 0;
    }

  }

  &:hover{
    .info{
      height: calc(130px + 120px);
    }
  }

  &.top{
    top: -25px;

    .info{
      height: calc(130px - 25px);
      bottom: 0px;
    }
    
    &:hover{
      .info{
        height: calc(130px - 25px + 80px);
      }
    }

  }

  &.center{
    .info{
      bottom: 0px;
    }
    &:hover{
      .info{
        height: calc(130px + 80px);
      }
    }
  }
  
  &.bottom{
    top: 25px;
    .info{
      justify-content: flex-end;
      top: 0px;
    }
  }

  $tabletBreakpoint: 991px;

  @media (max-width: $tabletBreakpoint) {

    border-radius: 50px;
    width: 20px;
    position: absolute;  // position is defined according to Timeline data (added at runtime)
    overflow: visible;
    
    .info{
      border-left: none;
      border-top: 1px solid var(--gray1);
      flex-direction: row;
      width: 130px;
      top: 5px;
      left: 5px;
      bottom: 0;
      left: 0;

      .text{
        padding-left: 0px;
        width: 110px;

        h3{
          font-size: 12px;
        }

        h5{
          font-size: 11px;
        }

        p.title{
          font-size: 10px;
          font-weight: 400;
        }

      }
    }

    &.bottom{
      left: -25px;

      .info{
        justify-content: flex-start;
        left: -127px;
        top: 5px;
      }

    }

    &.center{
      text-align: right;
      height: 200px;
      .info{
        width: calc(130px + 25px);
        justify-content: flex-end;
        left: 5px;
        top: 5px;
      }
    }
    
    &.top{
      left: 25px;
      text-align: right;
      .info{
        justify-content: flex-end;
        left: 5px;
        top: 5px;
      }
    }
    
  }

}