.topbar{
  width: 100%;
  height: fit-content;
  position: fixed;
  opacity: 0;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  
  .wrapper{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left{
      flex: 1;
      display: flex;
      justify-content: flex-start;
      font-size: 20px;

      h1{
        font-weight: 800;
      }
    }

    .socials{
      flex: 1;
    }

    .right{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      text-align: center;
      font-weight: 500;
      font-size: 17px;

      > * {
        padding-left: 3%;
      }
      
      a::after{
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background-color: var(--gray1);
        transition: all .5s;
      }

      //a:hover::after{
      //  width: 100%;
      //}

      a.active::after{
        width: 100%;
      }

    }
  }

  &.topbar-enter {
    opacity: 0;
  }

  &.topbar-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }

  &.topbar-exit {
    opacity: 1;
  }

  &.topbar-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }

  &.topbar-exit-done {
    opacity: 0;
  }

  &.topbar-enter-done {
    opacity: 1;
  }

  $phoneBreakpoint: 481px;

  @media (max-width: $phoneBreakpoint) {

    top: 100%;
    transform: translate(0%, -100%);
    height: 60px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


    .wrapper{
      background-color: var(--gray5);
      padding: 0;
      justify-content: center;
      height: 100%;

      .left{
        flex: 0;
        font-size: 15px;
      }

      .right{
        flex: 1;
        font-size: 10px;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        color: var(--gray3);
        font-weight: 700;

        .link{

          #icon{
            height: fit-content;
            font-size: 20px;
          }
          padding: 0%;
          //background-color: red;
        }

        a.active::after{
          width: 0%;
        }
      }

    }
      
  }
    
}
