.socials{
  font-size: 25px;
  display: flex;
  justify-content: center;
  text-align: center;
  height: fit-content;

  > * {
    padding: 0% 2%;
  }
}